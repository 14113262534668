@keyframes moveCloud {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(110vw); /* Move across the entire viewport width plus some margin */
  }
}

.cloud {
  animation: moveCloud 90s linear infinite;
  filter: drop-shadow(-15px 50px 5px rgba(0, 0, 0, 0.5))
}

body {
  overflow: hidden
}